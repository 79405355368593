<template>
  <v-app>
    <data-listener v-if="startListen"></data-listener>
    <v-alert
      dismissible
      style="z-index: 99999999"
      type="error"
      prominent
      v-if="accessDenied"
      >Você não têm permissão para acessar esta página.</v-alert
    >
    <v-app-bar
      app
      :color="whitelabel ? whitelabel.main_color : '#f5f5f5'"
      :dark="whitelabel ? whitelabel.dark == true : false"
      :light="whitelabel ? !whitelabel.dark == true : false"
      v-if="!isFillDataRoute && !isCameraViewClientPage"
    >
      <v-app-bar-nav-icon
        v-if="isMobile"
        @click.stop="$store.commit('setDrawer', !$store.state.drawer)"
      ></v-app-bar-nav-icon>
      <CondominiumSelector
        :props="{
          dense: true,
          hideDetails: true,
          width: '175px',
          maxWidth: '35vw',
        }"
      />
      <v-spacer v-if="user"></v-spacer>
      <softphone-component></softphone-component>
      <span v-if="user"
        >Usuário: <strong>{{ user.name }}</strong>
        <SpeakWithEntryControl />
        <SecurityGates />

        <NotificationsComponent class="ml-2" ref="notifications" />
      </span>
    </v-app-bar>
    <navigation-drawer v-if="!isLoginRoute && !isFillDataRoute"></navigation-drawer>
    <v-main>
      <v-overlay :value="loadingRoute">
        <v-row class="ma-0" justify="center" align="center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-row>
        <v-row class="ma-0" justify="center" align="center">
          <h5 class="mb-0 mt-2">Carregando</h5>
        </v-row>
      </v-overlay>
      <router-view></router-view>
      <notifications group="app" />
      <div
        :class="
          hasNotifications
            ? `content__notifications scrollable`
            : `content__notifications`
        "
      >
        <div class="btn__clear__section mt-2" v-if="hasNotifications">
          <v-btn @click="closeAllNotifications()" class="rounded-pill" dark color="blue">
            <v-icon>mdi-notification-clear-all</v-icon>
            Limpar Notificações
          </v-btn>
        </div>
        <div class="btn__clear__section mt-2" v-if="hasNotifications">
          <v-btn @click="stopAllNotifications()" class="rounded-pill" dark color="red">
            <v-icon>mdi-close</v-icon>
            Parar Notificações
          </v-btn>
        </div>
        <notifications group="custom" position="top left">
          <template slot="body" slot-scope="props">
            <div class="notification__content">
              <div class="img__section" v-if="props.item.data.img_notification">
                <v-spacer></v-spacer>
                <v-responsive width="80%" :aspect-ratio="1">
                  <img
                    :src="
                      props?.item?.data?.img_notification_url ?? $remoteNoPhotoGeneral()
                    "
                    alt=""
                  />
                </v-responsive>
                <v-spacer></v-spacer>
              </div>
              <div
                :class="
                  props.item.data.img_notification_url
                    ? 'text__section'
                    : 'text__section pl-2'
                "
              >
                <h4>{{ props.item.data.title }}</h4>
                <p>{{ props.item.data.body }}</p>
              </div>
              <v-btn
                color="white"
                icon
                class="btn__close__notification"
                small
                @click="
                  props.item.data.visualize(props.item.data.id);
                  props.close();
                "
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </template>
        </notifications>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  components: {
    NavigationDrawer: () => import("@components/NavigationDrawerComponent.vue"),
    HeaderComponent: () => import("@components/HeaderComponent.vue"),
    CondominiumSelector: () => import("@/CondominiumSelector.vue"),
    DataListener: () => import("@components/DataListener.vue"),
    NotificationsComponent: () => import("@components/NotificationsComponent.vue"),
    SpeakWithEntryControl: () => import("@components/SpeakWithEntryControlComponent.vue"),
    SecurityGates: () => import("@components/SecurityGates.vue"),
    SoftphoneComponent: () => import("@components/SoftPhoneComponent.vue"),
  },
  data() {
    return {
      loading: false,
      startListen: false,
      hasNotifications: false,
    };
  },
  computed: {
    route() {
      return this.$route?.path;
    },
    accessDenied() {
      return this.$store?.state?.accessDenied;
    },
    loadingRoute() {
      return this.$store?.state?.loadingRoute;
    },
    user() {
      return this.$store?.state?.user;
    },
    whitelabel() {
      return this.$store.state.whitelabel;
    },
    isLoginRoute() {
      return this.$route?.path.includes("/login");
    },
    isFillDataRoute() {
      return this.$route?.path.includes("/preenchimento/dados");
    },
    isCameraViewClientPage() {
      return this.$route?.path.includes("/cameraview");
    },
    isMobile() {
      return this.$store?.state?.mobile;
    },
  },
  watch: {},
  created() {
    window.addEventListener("resize", this.checkScreenType);
  },
  destroyed() {
    window.removeEventListener("resize", this.checkScreenType);
  },
  mounted() {
    this.checkScreenType();
    setTimeout(() => {
      this.startListen = true;
    }, 2000);
    setInterval(() => {
      this.checkNotifications();
    }, 1500);
  },
  methods: {
    checkScreenType() {
      if (window.mobileAndTabletCheck()) {
        this.$store.commit("setMobile", true);
      } else {
        this.$store.commit("setMobile", false);
      }
    },
    checkNotifications() {
      /**
       * O plugin não oferece uma opção de fazer um "dismiss all".
       * Muito menos oferece uma opção de listar as notificações ativas e permitir que sejam removidas uma a uma.
       * Então removeremos as notificações direto do DOM.
       */
      let notifications = document.querySelectorAll(
        ".content__notifications .vue-notification-wrapper"
      );
      if (notifications.length > 0) {
        this.hasNotifications = true;
      } else {
        this.hasNotifications = false;
      }
    },
    closeAllNotifications() {
      this.$notify({
        group: "custom",
        clean: true,
      });
      this.checkNotifications();
    },
    stopAllNotifications() {
      this.$store.commit("stopNotificationsReceivedByWebsocket");
      this.closeAllNotifications();
    },
  },
};
</script>

<style>
.notification__content {
  width: 100%;
  display: flex;
  background-color: green;
  color: white;
  margin: 0.25rem 0;
  padding: 0.6rem;
  border-radius: 20px;
  position: relative;
  animation: backgroundAnimated 1s linear infinite;
}

@keyframes backgroundAnimated {
  0% {
    background: green;
  }

  50% {
    background: rgb(91, 134, 120);
  }

  100% {
    background: green;
  }
}

.notification__content .img__section {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notification__content .img__section img {
  width: 100%;
  /* height: 100%; */
  border-radius: 50000px;
}

.notification__content .text__section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding-right: 0.6rem;
}

.notification__content .text__section h4 {
  font-size: 0.9rem;
  width: 100%;
  margin: 0;
  line-height: 1;
}

.notification__content .text__section p {
  line-height: 1.1;
  font-weight: normal;
  font-size: 0.7rem;
  width: 100%;
  margin: 0;
}

.btn__close__notification {
  position: absolute;
  right: 0.3rem;
  top: 0.3rem;
}

.content__notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: max-content;
  position: fixed;
  left: 0;
  top: 0;
  max-height: 100vh;
  z-index: 50000000;
}

.content__notifications .btn__clear__section {
  max-width: 300px;
}

.content__notifications .vue-notification-group {
  position: relative;
  flex: 1;
}

.content__notifications.scrollable .vue-notification-group {
  overflow: scroll;
}
</style>
